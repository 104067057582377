import { useNavigate } from 'react-router-dom';
// import { FaDiscord, FaTelegram, FaTwitter, FaBook } from 'react-icons/fa';
// import { MdLanguage } from 'react-icons/md';
// import { IoArrowForward } from 'react-icons/io5';
import FooterComponent from './../components/Landing/Footer';
import FaqComponent from './../components/Landing/FAQ';

// const IMAGE_PATH = process.env.PUBLIC_URL + '/assets/parallax-inner.svg';

export default function Landing() {
	const navigate = useNavigate();

	const goTg = () => {
		// <a href='https://t.me/powerful115'></a>
		// window.location.href = 'https://t.me/powerful115';
		window.open('https://t.me/powerful115', '_blank');
	};

	return (
		<div className='text-center bg-[#09121D] font-sans min-h-[100vh]'>
			<div className='z-10 flex flex-col items-center justify-center w-full landing-bg'>
				<div className='bg-[#09121DCC] absolute top-0 left-0 w-full h-full'></div>
				<div className='z-10 mt-10' data-aos='zoom-in'>
					<img
						src='/logo.png'
						style={{ width: '350px', height: 'auto' }}
						alt={'logo_img'}
					/>
				</div>
				<div
					className='text-white text-[40px] md:text-[64px] mx-10 font-bold mt-20 z-10'
					data-aos='fade-down'>
					Launch your own meme coin with our TrustAI PumpFun Launch Bundler
				</div>
				<div className='text-white text-[24px] m-10 z-10' data-aos='fade-up'>
					TrustAI PumpFun Launch Bundler is the leading meme coin launchpad with over 1359 coins
					launched and the highest average ROI in the industry
				</div>
				<div className='flex items-center justify-center gap-10 mb-20'>
					<button
						className='w-[140px] font-sans text-lg font-medium text-center text-white px-6 h-12  justify-center items-center gap-2.5 inline-flex bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none'
						onClick={() => navigate('/login')}>
						Login
					</button>
					<button
						className='w-[140px] font-sans text-lg font-medium text-center text-white px-6 h-12  justify-center items-center gap-2.5 inline-flex bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none'
						onClick={() => navigate('/register')}>
						Register
					</button>
					<button
						className='w-[140px] font-sans text-lg font-medium text-center text-white px-6 h-12  justify-center items-center gap-2.5 inline-flex bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none'
						onClick={goTg}>
						Contact
					</button>
				</div>
			</div>
			{/* <div
				className='z-20 flex flex-col w-3/4 p-4 m-auto mt-10'
				data-aos='zoom-out'>
				<h1 className='z-20 text-white mb-10 text-[40px] font-bold'>
					It is easy to use and effective for you.
				</h1>
				<video width='100%' className='z-20' height='auto' controls>
					<source src='./launchpad_video.mp4' type='video/mp4' />
					Your browser does not support the video tag.
				</video>
			</div> */}
			<div
				id='PumpFun'
				className='relative z-10 grid items-center gap-20 p-10 mx-auto md:grid-cols-2 max-w-7xl'>
				<div
					className=''
					data-aos='fade-right'
					data-aos-delay='1000'
					data-aos-offset='0'>
					<img src='./pumpfun_trading.png' alt='' className='grayscale' />
				</div>
				<div
					className='flex flex-col text-left'
					data-aos='fade-left'
					data-aos-delay='1000'
					data-aos-offset='0'>
					<h1 className='text-white text-[40px] font-bold'>
						Pump.fun Launch Bundler
					</h1>
					<p className='text-white leading-10 text-[1rem]'>
						Transform your creative blockchain ideas into reality with our
						Pump.fun Launch Bundler. Designed for developers, creators, and
						visionaries, our platform offers unmatched ease of use, robust
						security, and comprehensive support to ensure your project's
						success.
					</p>
					<ul className='mt-4 text-white  leading-10 text-[1.2rem] list-disc list-inside'>
						<li>
							Firt Buy with Multi wallets: You can buy the meme coins first by
							20 wallets.
						</li>
						<li>
							Disperse coins to Mutli wallets: You can disperse the meme coins
							to more 100 wallets.
						</li>
						<li>
							Support the volume bot and sniper bot: You can use it, it is
							helpful for you.
						</li>
						<li>
							User-Friendly Interface: Our platform is designed to make project
							creation and management simple and enjoyable.
						</li>
						<li>
							Expert Support: Benefit from our dedicated support team, ready to
							assist you at every stage of your journey.
						</li>
						{/* <li>
							Proven Success: Join a growing community of successful projects
							that have launched and thrived on our platform.
						</li> */}
					</ul>
				</div>
			</div>
			<section className='relative w-full mt-2 bg-center bg-cover way-bg min-w-screen max-w-screen'>
				<div className='bg-[#09121DCC] absolute top-0 left-0 w-full h-full'></div>
				<div className='relative z-10 grid items-center p-6 mx-auto md:grid-cols-2 max-w-7xl'>
					<div
						className='flex flex-col px-4'
						data-aos='fade-down'
						data-aos-delay='300'
						data-aos-offset='0'>
						<h1 className='text-white text-[40px] font-bold'>
							Become Our Potential Partner
						</h1>
						<p className=' leading-10 text-[1.2rem] text-white'>
							Are you ready to take your blockchain projects to the next level?
							Partner with us and leverage our state-of-the-art Launchpad
							platform, designed to empower developers, entrepreneurs, and
							innovators across Pump.fun networks. Together, we can drive the
							future of blockchain technology.
						</p>
					</div>
					<div
						className='flex justify-end'
						data-aos='fade-up-right'
						data-aos-delay='800'
						data-aos-offset='0'>
						<img src='./way_launch.png' className='grayscale' alt='' />
					</div>
				</div>
			</section>
			<FaqComponent />
			<FooterComponent />
		</div>
	);
}
