import { useState } from 'react';
import Modal from '../Base/Modal';

export default function BuyTokensDialog({ isOpen, onOK, onCancel }) {
	const [count, setCount] = useState('');
	const [minSol, setMinSol] = useState('');
	const [maxSol, setMaxSol] = useState('');
	const [isBundle, setIsBundle] = useState(false);

	const handleIsBundle = (e) => {
		const newWalletAllChecked = !isBundle;
		setIsBundle(newWalletAllChecked);
	};

	const handleOK = () => {
		if (count !== '') {
			setCount('');
			setMinSol('');
			setMaxSol('');
			onOK(count, minSol, maxSol, isBundle);
		}
	};

	const handleCancel = () => {
		setCount('');
		setMinSol('');
		setMaxSol('');
		onCancel();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleCancel}>
			<div className='flex flex-col pt-5 w-[440px] font-sans'>
				<div className='flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight'>
					<div className='font-sans text-sm font-medium text-white uppercase'>
						Generate Pump Wallets to Buy
					</div>
				</div>
				<div className='items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md'>
					{/* <div className='mt-4'>
						<div className='flex items-center font-sans text-xs text-center uppercase text-gray-normal'>
							<input
								type='checkbox'
								id='butTotakCheck'
								className='w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0'
								checked={isBundle}
								onChange={handleIsBundle}
							/>
							<label
								className='ml-4 font-sans text-xs uppercase text-gray-normal'
								htmlFor='butTotakCheck'>
								Use bundle
							</label>
						</div>
					</div> */}
					<div className='mt-4'>
						<div className='font-sans text-xs uppercase text-gray-normal'>
							Wallet Count<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							className='outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1'
							placeholder='Enter Wallet Count'
							value={count}
							onChange={(e) => setCount(e.target.value)}
						/>
					</div>
					<div className='mt-4'>
						<div className='font-sans text-xs uppercase text-gray-normal'>
							Minimum Sol Amount<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							className='outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1'
							placeholder='Enter minium sol amount'
							value={minSol}
							onChange={(e) => setMinSol(e.target.value)}
						/>
					</div>
					<div className='mt-4'>
						<div className='font-sans text-xs uppercase text-gray-normal'>
							Maximum Sol Amount<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							className='outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1'
							placeholder='Enter maximum sol amount'
							value={maxSol}
							onChange={(e) => setMaxSol(e.target.value)}
						/>
					</div>

					<div className='flex items-center justify-center gap-5 my-5'>
						<button
							className='pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap'
							onClick={handleOK}>
							OK
						</button>
						<button
							className='pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap'
							onClick={handleCancel}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
